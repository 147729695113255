<template>
    <v-card max-width="1200" class="mx-auto "  >      
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid>
            <h2>Account</h2>
            
        <div class="text-subtitle-1 text-medium-emphasis">Account</div>
  
        <v-text-field density="compact" placeholder="Email address" prepend-inner-icon="mdi-email-outline" v-model="user.email"
          variant="outlined" readonly=""></v-text-field>
  
        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Old Password          
        </div>
  
        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          density="compact" placeholder="Enter your old password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
          @click:append-inner="visible = !visible" v-model="password"></v-text-field>
          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          New Password          
        </div>
          <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          density="compact" placeholder="Enter your new password" prepend-inner-icon="mdi-lock-outline" variant="outlined"
          @click:append-inner="visible = !visible" v-model="new_password"></v-text-field>
  
        <v-btn block class="mb-8" color="blue" size="large" variant="tonal" v-on:click="tryChangePassword()">
          Change Password
        </v-btn>
  
 
                                            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";

export default {
  data: () => ({
      visible: false,
      password: "",
      new_password: ""
    }),
  setup() {
    const user = useUserStore();        
    return{user}
  },  
  components: {
    AppBar
    },
    mounted(){
      
    },
    methods:{
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    tryChangePassword()
        {
            let LoginData = {
                Email:this.user.email,
                Password:this.password,
                NewPassword: this.new_password
            }                        
            axios.post("https://mediatrunk.net/mobilevids/change_pw.php", LoginData)
            .then(response => {
                
              alert(response.data.reason);                
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
  }

}
</script>