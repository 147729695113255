<template>
    <v-card max-width="1200" class="mx-auto "  >      
      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>
          

            <v-data-table
                :search="search"
                v-model:expanded="expanded"
                :headers="this.userHeaders"
                :items="this.users"
                item-value="user_id"
                show-expand
                @item-expanded="loadDetails"
            >
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Users</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                            {{ item.mail }}
                        </template>

                        <template v-slot:subtitle>
                         Assigned To: {{ item.server_name }}
                        </template>

                        <template v-slot:text>
                        <p>Jellyfin Username: {{ item.jellyfin_username }}</p> 
                        <p>Jellyfin ID: {{ item.jellyfin_userid }}</p> 
                        <p v-if="item.access==1">Access: Yes</p>
                        <p v-else>Access: No</p>
                        <p>IP Address: {{item.last_ipaddress}}</p>

                        </template>
                        <template v-slot:actions>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Change Server"
                            rounded="0"
                            variant="flat"></v-btn>
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>   
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";

export default {
  data: () => ({
      visible: false,
      search: '',
      password: "",
      expanded: [],
      new_password: "",
      users: [],
        userHeaders: [
          {
            title: 'UserID',
            align: 'start',
            sortable: false,
            key: 'user_id',
          },          
          { title: 'E-mail', key: 'mail' },
          { title: 'Billing ID', key: 'billing_id' },          
          { title: 'Renewed', key: 'last_payment' },

          
          
          { title: '', key: 'data-table-expand' },
        ],
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getUsers();
    },
    methods:{
    loadDetails({item})
    {
        alert("kekke" + item.id);
    },        
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    getUsers()
        {
            let LoginData = {
                Email:this.user.email,
                Password:this.password,
                NewPassword: this.new_password
            }                        
            axios.post("https://mediatrunk.net/mobilevids/admin/users.php", LoginData)
            .then(response => {
                this.users = response.data.users;                          
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
  }

}
</script>