<template>

<v-app-bar color="teal-darken-4" image="https://picsum.photos/1920/1080?random"
permanent
    left>
          <template v-slot:image>
            <v-img
              gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
            ></v-img>
          </template>  
          <template v-slot:prepend>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          </template>  
          <v-app-bar-title>MobileVids- Admin</v-app-bar-title>  
          <v-spacer></v-spacer>   
          <v-btn color="indigo-darken-3" variant="flat" prepend-icon="mdi-arrow-left" @click="BackToMain()">Back</v-btn>
          <v-menu open-on-hover>
      <template v-slot:activator="{ props }">
        <v-btn icon
          color="primary"
          v-bind="props"
        >
        <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="handleClick(index)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
          </v-menu>
          
        </v-app-bar>
        <v-navigation-drawer
        v-model="drawer"
        location="left"
        temporary>
        <v-list>
        <v-list-item
              v-for="(item, index) in drawerItems"
              :key="index"
              @click="handleDrawerClick(index)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

      </v-list>
      </v-navigation-drawer>

</template>
<script>
import { useUserStore } from '../stores/user'
import router from '@/router';
import { ref } from 'vue'
export default {

  setup() {
    const user = useUserStore();
    
    window.store = {user};
    const visible = false;
    const  drawer = ref(false);
    const  items =  [
        { title: 'Account',
          click(){
            router.push("/account")          
          },
          
        },
        { title: 'Sign Out',
          click(){
            user.signOut();            
          },
          
        }];
    const drawerItems =  [    
   
       ];
       if(user.role==9)
      {
        drawerItems.push( {
          title: 'Overview',
          value: 'overview',
          click(){
            router.push("/admin")
          }          
        });
        drawerItems.push( {
          title: 'Users',
          value: 'users',
          click(){
            router.push("/a:users")
          }          
        });
        drawerItems.push( {
          title: 'Servers',
          value: 'servers',
          click(){
            router.push("/a:servers")
          }          
        });

      }
      function handleClick (index){
        items[index].click.call(this)
      }
      function handleDrawerClick (index){
        drawerItems[index].click.call(this)
      }
      function BackToMain()      
      {                 
          window.open("https://mvids.org",'_self');          
          
      }

    return{visible,drawer,user,items,drawerItems, handleClick,handleDrawerClick,BackToMain}
  },  

}
</script>